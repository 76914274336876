<template>
  <div class="subscribeVerify">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input
          placeholder="账号"
          v-model="searchParam.account"
          :width="250"
          label="账号"
        ></v-input>
        <v-input
          placeholder="	地址名称"
          v-model="searchParam.openUrlName"
          :width="250"
          label="	地址名称"
        ></v-input>
        <v-select
          clearable
          :options="auditStatusOptions"
          v-model="searchParam.auditStatus"
          label="审核状态"
          @change="$refs.list.getList()"
        />
        <v-select
          clearable
          :options="validStatusList"
          v-model="searchParam.validStatus"
          label="有效状态"
          @change="$refs.list.getList()"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="oEdit(scope.row)" />
        <v-button
          v-if="scope.row.auditStatus == 1"
          text="审核"
          type="text"
          @click="verify(scope.row.id)"
        />
      </template>
    </v-list>
    <v-dialog
      title="提示"
      v-model="isEditdialog"
      @cancel="canEditcel"
      @confirm="confirmEdit"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="item">
        <v-select
          :options="validStatusList"
          v-model="formEdit.validStatus"
          label="有效状态"
        />
      </div>
      <div class="item">
        <v-input
          placeholder="请输入调用次数上限"
          v-model="formEdit.limitPerDay"
          :width="250"
          label="调用次数上限"
        ></v-input>
      </div>
    </v-dialog>
    <v-dialog
      title="提示"
      v-model="isdialog"
      @cancel="cancel"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="确定"
    >
      <div class="item">
        <v-select
          :options="verifyAuditStatus"
          v-model="form.auditStatus"
          label="审核状态"
        />
      </div>
      <div class="item">
        <v-input
          type="textarea"
          placeholder="请输入审核备注"
          v-model="form.auditRemarks"
          :width="250"
          label="审核备注"
        ></v-input>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getSubscribeVerifyUrl,
  editVerifyUrl,
  subscribeVerifyUrl,
} from "./api.js";
import {
  auditStatusMap,
  auditStatusOptions,
  verifyAuditStatus,
  verifyAuditStatusMap,
  validStatusList,
  validStatusListMap,
} from "./map";

export default {
  name: "subscribeVerify",
  data() {
    return {
      isEditdialog: false,
      validStatusList,
      isdialog: false,
      verifyAuditStatus,
      id: "",
      auditStatusOptions,
      searchParam: {
        account: "",
        validStatus: "",
        auditStatus: "",
        openUrlName: "",
      },
      form: {
        auditStatus: 2,
        auditRemarks: "",
      },
      formEdit: {
        id: "",
        validStatus: "",
        limitPerDay: "",
      },
      communitList: [],
      tableUrl: getSubscribeVerifyUrl,
      headers: [
        {
          prop: "openId",
          label: "接口ID",
        },
        {
          prop: "openUrl",
          label: "url地址",
        },
        {
          prop: "openUrlName",
          label: "url地址名称",
        },
        {
          prop: "limitPerDay",
          label: "调用次数上限",
        },
        {
          prop: "auditTime",
          label: "审核时间",
        },
        {
          prop: "account",
          label: "账号",
        },
        {
          prop: "validStatus",
          label: "有效状态",
          formatter: (row, prop) => {
            return validStatusListMap[row.validStatus];
          },
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => {
            return auditStatusMap[row.auditStatus];
          },
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    oEdit(row) {
      this.formEdit.id = row.id;
      this.formEdit.validStatus = row.validStatus;
      this.formEdit.limitPerDay = row.limitPerDay;
      this.isEditdialog = true;
    },
    confirmEdit() {
      let params = {
        id: this.formEdit.id,
        validStatus: this.formEdit.validStatus,
        limitPerDay: this.formEdit.limitPerDay,
      };
      this.$axios
        .post(`${editVerifyUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.isEditdialog = false;
            this.$refs.list.search();
          } else {
          }
        });
    },
    canEditcel() {
      this.isEditdialog = false;
    },
    verify(id) {
      this.isdialog = true;
      this.id = id;
    },
    cancel() {
      this.isdialog = false;
    },
    confirm() {
      this.postVerify();
    },
    postVerify() {
      let params = {
        id: this.id,
        auditStatus: this.form.auditStatus,
        auditRemarks: this.form.auditRemarks,
      };
      this.$axios
        .post(`${subscribeVerifyUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.isdialog = false;
            this.$refs.list.search();
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.subscribeVerify {
  box-sizing: border-box;
  height: 100%;
  .item {
    margin: 10px 0;
    /deep/ .v-input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
